.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modalContent {
  padding: 20px;
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  border: 1px solid #888;
  width: 30%; /* Could be more or less, depending on screen size */
}

.close {
  color: #aaa !important;
  float: right;
  font-size: 28px;
  font-weight: bold;
  margin-left: auto;
}

.close:hover,
.close:focus {
  color: black !important;
  text-decoration: none;
  cursor: pointer;
}

.header {
  display: flex;
  background-color: #f0f0f0;
  margin-top: 2%;
  padding: 0 5%;
}

.buttonExport {
  justify-content: center;
  text-align: center;
  margin: auto;
  color: #fdfdfd;
  background-color: #0b0b0b;
  padding: 10px;
  height: 50%;
  border-radius: 5px;
}
