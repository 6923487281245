.header {
  background-color: #0b0b0b;
  width: 100%;
  height: 10%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding-left: 2%;
  overflow: hidden;
}
