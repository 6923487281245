.container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
  width: 80%;
  height: 80%;
}

.center {
  width: 50%;
}

.center div > input {
  width: 85%;
  height: 40px;
  background-color: #f3f4f5;
  border: none;
  text-align: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.center div > input:focus {
  outline: none;
}

.center div > button {
  width: 10%;
  height: 40px;
  background-color: #ce0000;
  color: #fdfdfd;
  border: none;
  text-align: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.center div > button:disabled {
  background-color: #999999;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  padding-bottom: 20px;
  justify-content: center;
  align-items: center;
  background-color: #fdfdfd;
}

.footer div {
  width: 25%;
  padding: 0;
}

.borderRight {
  border-right: solid 2px #e3e3e3;
}
