.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modalContent {
  background-color: #fefefe;
  margin: 5% auto; /* 15% from the top and centered */
  border: 1px solid #888;
  width: fit-content; /* Could be more or less, depending on screen size */
}

.companyInfo {
  display: flex;
  background-color: #f0f0f0;
  margin-top: 2%;
  padding: 0 5%;
}

.companyInfo h2 {
  margin-left: 20px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  margin-left: auto;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.details {
  display: flex;
  padding: 0 5%;
  margin: 3% 0;
  justify-content: center;
  align-items: center;
}

.leftPanel {
  padding-left: 1%;
  display: flex;
  flex-direction: column;
}

.leftPanel .profilePicture {
  border-radius: 5px;
  margin-right: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.location {
  display: flex;
  flex-direction: row;
}

.location p {
  margin-left: 10px;
}

.rightPanel {
  width: 70%;
}

.rightPanel h1 {
  margin-bottom: 10px;
  padding: 0;
}

.rightPanel a {
  color: #999999;
  text-decoration: none;
}

.rightPanel a:hover {
  color: inherit;
}

.tableWrapper {
  background-color: #f3f4f5;
  flex-direction: column;
  justify-content: center;
  display: flex;
  padding: 4% 5%;
  width: auto;
  border-radius: 10px;
  margin-top: 10px;
}

.tableWrapper h3 {
  margin: 2px;
  text-align: center;
}

table {
  overflow-y: scroll;
  height: 200px;
  display: block;
}

.divisor {
  background-color: #000000;
  width: 100%;
  height: 1px;
}

td {
  display: flex;
  flex-direction: row;
  padding-bottom: 1px;
}

td p {
  margin-bottom: 2px;
}

.boldText {
  font-weight: bold;
  padding-right: 10px;
}
