.container {
  justify-content: center;
}

.divisor {
  background-color: #000000;
  width: 100%;
  height: 2px;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  height: 60%;
  width: 96vw;
  justify-content: flex-start;
  margin: 1% 2%;
}

.error {
  height: 60vh;
  width: 96vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.error span {
  color: #ce0000;
}
