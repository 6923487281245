.card {
  width: 15vw;
  height: fit-content;
  position: relative;
  background-color: #f3f4f5;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 2%;
  margin-left: 2vw;
  margin-right: 1vw;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.status {
  display: flex;
  background-color: #999999;
  height: 2vh;
  padding: 3%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  justify-content: center;
  align-items: center;
}

.status p {
  margin: 0;
}

.companyInfo {
  margin: 10%;
}

.divisor {
  background-color: #e4e4e4;
  width: 100%;
  height: 2px;
}

.optionsContainer {
  display: flex;
  flex-direction: row;
  padding: 5px 20px;
  justify-content: space-around;
  align-items: center;
}

.optionsContainer button {
  background: none;
  color: inherit;
  border: none;
  outline: inherit;
  cursor: pointer;
}

.result {
  display: flex;
  height: 2vh;
  padding: 3%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  justify-content: center;
  align-items: center;
}

.result p {
  font-weight: bold;
  margin: 0;
  padding: 2px;
}

.found {
  background-color: #169723;
}

.notFound {
  background-color: #979797;
}

.error {
  background-color: #9e1221;
}

.animateLeft {
  animation: enterLeft 1s;
}

@keyframes enterLeft {
  from {
    left: -100px;
    opacity: 0;
  }
  to {
    left: 0px;
    opacity: 1;
  }
}
